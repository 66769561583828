import {
  createContext,
  useContext,
  useState,
  useEffect,
  forwardRef,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const MessageContext = createContext({});

export function useMessage() {
  return useContext(MessageContext);
}

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
));

export function MessageProvider({ children }) {
  const [value, setValue] = useState(false);
  const [open, setOpen] = useState(false);

  const data = {
    setWarning: (message) => setValue({ message, type: "warning" }),
    setError: (message) => setValue({ message, type: "error" }),
    setInfo: (message) => setValue({ message, type: "info" }),
    setSuccess: (message) => setValue({ message, type: "success" }),
  };

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (value) setOpen(true);
  }, [value]);

  return (
    <MessageContext.Provider value={data}>
      <Snackbar
        open={open}
        TransitionProps={{
          onExited: () => setValue(false),
        }}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ spacing: 10 }}
      >
        <Alert
          variant="outlined"
          onClose={onClose}
          severity={value.type}
          sx={{ backgroundColor: "background.paper" }}
        >
          {value.message}
        </Alert>
      </Snackbar>

      {children}
    </MessageContext.Provider>
  );
}
