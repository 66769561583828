import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import { LoadingButton } from "../../components/Loading";
import { usePessoa } from "../Pessoa/PessoaContext";
import { usePessoaAgendamento } from "../PessoaAgendamento/PessoaAgendamentoContext";
import { useFaltasInjustificadas } from "./useFaltasInjustificadas";

export const FaltasInjustificadas = () => {
  const { faltas, removeFalta } = useFaltasInjustificadas();
  const [selectedFalta, setSelectedFalta] = useState(false);
  const { pessoa } = usePessoa();
  const { updateAgendamento, isLoading } = usePessoaAgendamento();

  const handleClickOpen = (falta) => () => setSelectedFalta(falta);
  const handleClose = () => setSelectedFalta(false);
  const onSubmit = (event) => {
    event.preventDefault();
    removeFalta(selectedFalta.id);
    updateAgendamento({ ...selectedFalta, ausenciaJustificada: true });
  };

  useEffect(() => {
    if (!isLoading) setSelectedFalta(false);
  }, [isLoading]);

  if (!faltas.length) return null;

  return (
    <>
      <Tooltip title="Faltas injustificadas no ano atual">
        <Typography variant="h6">Faltas injustificadas</Typography>
      </Tooltip>
      <Typography variant="body2">
        Total: <b>{faltas.length}</b>
      </Typography>
      <List>
        {faltas.map((falta) => (
          <ListItem
            key={falta.id}
            secondaryAction={
              <Tooltip title="Justificar ausência">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={handleClickOpen(falta)}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemText
              primary={falta.setorAtendimento.nome}
              secondary={format(new Date(falta.dataAgendamento), "dd/MM/yyyy")}
            />
          </ListItem>
        ))}
      </List>
      {selectedFalta && (
        <Dialog
          open={true}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit,
          }}
        >
          <DialogTitle>Justificar ausência</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Justificar a ausência de <b>{pessoa.nome}</b> para o agendamento
              feito em{" "}
              <b>
                {format(new Date(selectedFalta.dataAgendamento), "dd/MM/yyyy")}
              </b>{" "}
              para o setor <b>{selectedFalta.setorAtendimento.nome}</b>
              {selectedFalta.profissional.nome && (
                <>
                  com o profissional <b>{selectedFalta.profissional.nome}</b>
                </>
              )}
            </DialogContentText>
            <TextField
              autoFocus
              label="Obs"
              fullWidth
              value={selectedFalta.descricao}
              onChange={(e) =>
                setSelectedFalta({
                  ...selectedFalta,
                  descricao: e.target.value,
                })
              }
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <LoadingButton type="submit" loading={isLoading}>
              Confirmar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
