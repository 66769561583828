import { useEffect, useState } from "react";
import { Atendimento } from "../../api/api";
import { useAuth } from "../Auth/AuthContext";

export const useFaltasInjustificadas = (anoBase) => {
  const { user } = useAuth();
  const [faltas, setFaltas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    const { data } = await Atendimento(user.jwt).faltasInjustificadas(anoBase);

    setFaltas(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    load(anoBase);
  }, [anoBase]);

  return { faltas, isLoading };
};
