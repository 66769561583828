import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Autocomplete from "@mui/material/Autocomplete";
import MUIRichTextEditor from "mui-rte";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import { useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";

import StyledRoot, { classes } from "../../utils/pageStyles";
import { normalize } from "../../utils/normalize";
import { PessoaEmAtendimento } from "../Pessoa/PessoaEmAtendimento";
import { LoadingButton } from "../../components/Loading";
import { AtendimentoSetor } from "../AtendimentoSetor/AtendimentoSetor";
import {
  usePessoaAgendamento,
  defaultData,
} from "../PessoaAgendamento/PessoaAgendamentoContext";
import { usePessoa } from "../Pessoa/PessoaContext";
import { ProfissionalSetor } from "../ProfissionalSetor/ProfissionalSetor";
import { useInit } from "../Router/InitContext";
import { useAuth } from "../Auth/AuthContext";

import { usePessoaAgendamentoAnexos } from "../PessoaAgendamento/PessoaAgendamentoAnexosContext";
import { LaudoReceita } from "./LaudoReceita";
import { DesligamentoMessage } from "../PessoaDesligamento/DesligamentoMessage";

export function PessoaAtendimento({ setorName }) {
  const { user } = useAuth();
  const { setoresAtendimento } = useInit();
  const { id } = useParams();
  const pessoaAgendamento = usePessoaAgendamento();
  const { pessoa } = usePessoa();
  const { clearAnexos, anexos } = usePessoaAgendamentoAnexos();
  const [agendamento, setAgendamento] = useState(defaultData);
  const [hasAgendamento, setHasAgendamento] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorInitialState, setEditorInitialState] = useState();

  const loadEditorState = (value) => {
    const contentHTML = convertFromHTML(value);
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    setEditorInitialState(JSON.stringify(convertToRaw(state)));
  };

  const loadAgendamento = (a) => {
    const setorAtendimento =
      setoresAtendimento.find((s) => setorName === normalize(s.nome)) || {};

    setHasAgendamento(!!a.id);
    setAgendamento({
      ...a,
      ...(!a.id ? { setorAtendimento } : {}),
    });

    loadEditorState(a.descricao);
  };

  useEffect(() => {
    (async () => {
      if (!setoresAtendimento.length) return;

      if (id && id !== pessoaAgendamento.agendamento.id) {
        return loadAgendamento(await pessoaAgendamento.loadAgendamento(id));
      }

      loadAgendamento(defaultData);
      clearAnexos();
    })();
  }, [
    id,
    pessoaAgendamento.agendamento.id,
    pessoa.id,
    setoresAtendimento,
    setorName,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const id = await pessoaAgendamento.saveAtendimento({
      ...agendamento,
      realizado: true,
      descricao: stateToHTML(editorState.getCurrentContent()),
    });

    setAgendamento({ ...agendamento, id });
  };

  const listAgendamento = [
    { id: "", label: "Atendimento sem agendamento" },
    ...pessoaAgendamento.listAgendamento.map((a) => ({
      id: a.id,
      label: format(new Date(a.dataAgendamento), "dd/MM/yyyy H:mm"),
    })),
  ];

  const profissionalUserId =
    agendamento.profissional?.user?.id ?? agendamento.profissional?.user;

  const canEdit = profissionalUserId === user.user.id;

  return (
    <Container component="main" maxWidth="md">
      <StyledRoot className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PlaylistAddCheckIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Atendimento
        </Typography>

        {pessoa.id && (
          <Typography component={Box} minHeight={24} variant="subtitle2">
            {agendamento?.setorAtendimento?.nome}
          </Typography>
        )}

        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {pessoaAgendamento.isLoading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            <PessoaEmAtendimento />
            <DesligamentoMessage />

            {pessoa.id && !pessoa.desligada && (
              <>
                <Grid item xs={12} sm={6}>
                  {hasAgendamento ? (
                    <TextField
                      label="Ref. ao agendamento"
                      variant="outlined"
                      value={format(
                        new Date(agendamento.dataAgendamento),
                        "dd/MM/yyyy H:mm"
                      )}
                      fullWidth
                      readOnly
                    />
                  ) : (
                    <Autocomplete
                      options={listAgendamento}
                      getOptionLabel={(option) => option.label}
                      value={listAgendamento.find(
                        (a) => a.id === agendamento.id
                      )}
                      onChange={(e, { id }) =>
                        setAgendamento({ ...agendamento, id })
                      }
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ref. ao agendamento"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  )}
                </Grid>

                <ProfissionalSetor
                  gridSetorProps={{ xs: 12, sm: 6 }}
                  gridProfissionalProps={{ xs: 12, sm: 6 }}
                  setorAtendimento={agendamento.setorAtendimento}
                  onChangeSetor={(e, setorAtendimento) =>
                    setAgendamento({ ...agendamento, setorAtendimento })
                  }
                  profissional={agendamento.profissional}
                  onChangeProfissional={(e, profissional) =>
                    setAgendamento({ ...agendamento, profissional })
                  }
                />

                {pessoa.id && (
                  <Grid item xs={12} sm={6} className="only-printer">
                    <TextField
                      label="Pront. Físico"
                      variant="outlined"
                      value={pessoa.numeroProntuario || "-"}
                      fullWidth
                      readOnly
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <AtendimentoSetor setor={agendamento.setorAtendimento} />
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <Typography fontWeight="bold">Evolução</Typography>
                  <MUIRichTextEditor
                    label="... detalhamento do atendimento"
                    inlineToolbar={false}
                    editorState={editorState}
                    onChange={setEditorState}
                    defaultValue={editorInitialState}
                  />
                </Grid>

                <LaudoReceita
                  agendamento={agendamento}
                  laudo={agendamento.detalhamento?.laudo}
                  receita={agendamento.detalhamento?.receita}
                  onLaudoChange={(laudo) =>
                    setAgendamento({
                      ...agendamento,
                      detalhamento: { ...agendamento.detalhamento, laudo },
                    })
                  }
                  onReceitaChange={(receita) =>
                    setAgendamento({
                      ...agendamento,
                      detalhamento: { ...agendamento.detalhamento, receita },
                    })
                  }
                />

                <Grid item xs={12}>
                  <Typography fontWeight="bold">Declaração</Typography>
                  <TextField
                    fullWidth
                    onChange={(e) =>
                      setAgendamento({
                        ...agendamento,
                        declaracao: e.target.value,
                      })
                    }
                    value={agendamento.declaracao}
                    placeholder="Declaração"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid item xs={6}>
                  <LoadingButton
                    type="submit"
                    loading={pessoaAgendamento.isLoading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    disabled={!canEdit || pessoaAgendamento.isLoading}
                  >
                    Salvar
                  </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    onClick={() => window.print()}
                    className={classes.button}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                </Grid>
                {!canEdit && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      O atendimento pode ser{" "}
                      {agendamento.dataAtendimento
                        ? "atualizado "
                        : "realizado "}
                      apenas pelo profissional responsável
                    </Alert>
                  </Grid>
                )}
                {agendamento.dataAtendimento && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Atendimento realizado em{" "}
                      <b>
                        {format(
                          new Date(agendamento.dataAtendimento),
                          "dd/MM/yyyy H:mm"
                        )}
                      </b>{" "}
                      pelo profissional <b>{agendamento.profissional.nome}</b>
                    </Alert>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </form>
      </StyledRoot>
    </Container>
  );
}
