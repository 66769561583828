import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import grey from "@mui/material/colors/grey";

const lightTheme = {
  palette: {
    primary: {
      main: "#1d11bc",
    },
    secondary: {
      main: "#f50057",
    },
  },
  overrides: {
    MUIRichTextEditor: {
      editor: {
        minHeight: 100,
        border: `solid 1px ${grey[400]}`,
        borderRadius: 4,
        padding: "4px 8px",
      },
    },
  },
};

const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#1375b8",
    },
    secondary: {
      main: "#f50057",
    },
  },
  overrides: {
    MUIRichTextEditor: {
      editor: {
        minHeight: 100,
        border: `solid 1px ${grey[400]}`,
        borderRadius: 4,
        padding: "4px 8px",
      },
    },
  },
};

export const Theme = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = createTheme(prefersDarkMode ? darkTheme : lightTheme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
