import Container from "@mui/material/Container";
import StyledRoot, { classes } from "../../utils/pageStyles";
import Avatar from "@mui/material/Avatar";
import RuleIcon from "@mui/icons-material/Rule";
import Typography from "@mui/material/Typography";
import { useFaltasInjustificadas } from "./useFaltasInjustificadas";
import { FormControl, Grid, LinearProgress, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
import { usePessoa } from "../Pessoa/PessoaContext";
import { useState } from "react";

export const ControleFaltas = () => {
  const { getPessoaById, isLoading: isLoadingPessoa } = usePessoa();
  const currentYear = new Date().getFullYear();
  const [anoBase, setAnoBase] = useState(currentYear);
  const { faltas, isLoading } = useFaltasInjustificadas(anoBase);

  const onClick = (falta) => () => {
    getPessoaById(falta.pessoaId);
  };

  return (
    <Container component="main" maxWidth="md">
      <StyledRoot className={classes.paper}>
        <Avatar className={classes.avatar}>
          <RuleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Controle de Faltas
        </Typography>
        <LinearProgress />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="ano-base">Ano Base</InputLabel>
              <Select
                value={anoBase}
                labelId="ano-base"
                label="Ano Base"
                onChange={({ target: { value } }) => setAnoBase(value)}
              >
                <MenuItem value={currentYear}>{currentYear}</MenuItem>
                <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Alert severity="info" sx={{ width: "100%", py: "2px" }}>
              Listagem de paciente ativos com faltas não justificadas, agrupadas
              por dia
            </Alert>
          </Grid>
        </Grid>
        {(isLoading || isLoadingPessoa) && (
          <LinearProgress sx={{ width: "100%", mt: 2 }} />
        )}
        {!isLoading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Pront.</TableCell>
                <TableCell>
                  <Tooltip title="Prontuário físico">
                    <span>Físico</span>
                  </Tooltip>
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Ausencias</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faltas.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>Nenhum registro encontrado</TableCell>
                </TableRow>
              )}
              {faltas.map((faltas) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={faltas.id}
                  sx={{ cursor: "pointer" }}
                  onClick={onClick(faltas)}
                >
                  <TableCell>{faltas.pessoaId}</TableCell>
                  <TableCell>{faltas.numeroProntuario}</TableCell>
                  <TableCell>{faltas.nome}</TableCell>
                  <TableCell>{faltas.ausencias}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </StyledRoot>
    </Container>
  );
};
