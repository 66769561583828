import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CloseIcon from "@material-ui/icons/Close";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AccessibleIcon from "@material-ui/icons/Accessible";
import HearingIcon from "@material-ui/icons/Hearing";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FaceIcon from "@material-ui/icons/Face";
import TodayIcon from "@material-ui/icons/Today";
import ListAltIcon from "@mui/icons-material/ListAlt";
import RuleIcon from "@mui/icons-material/Rule";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip from "@mui/material/Tooltip";
import { Logout } from "./Logout";

export const Menu = ({ toggleDrawer, open }) => (
  <div>
    <List>
      <ListItem button onClick={toggleDrawer}>
        <ListItemIcon>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ListItemIcon>
      </ListItem>
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Início" />
      </ListItem>
      <ListItem button component={Link} to="/novo-cadastro">
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Novo Cadastro" />
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListSubheader inset>Em Atendimento</ListSubheader>
      <ListItem button component={Link} to="/paciente">
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText primary="Paciente" />
      </ListItem>
      <ListItem button component={Link} to="/plano-terapeutico">
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Plano Terapêutico" />
      </ListItem>
      <ListItem button component={Link} to="/agendamento">
        <ListItemIcon>
          <TodayIcon />
        </ListItemIcon>
        <ListItemText primary="Agendamento" />
      </ListItem>
      <ListItem button component={Link} to="/frequencia">
        <ListItemIcon>
          <PlaylistAddCheckIcon />
        </ListItemIcon>
        <ListItemText primary="Frequência" />
      </ListItem>
      <ListItem button component={Link} to="/atendimento">
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Atendimento" />
      </ListItem>
      <ListItem button component={Link} to="/desligamento">
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText primary="Desligamento" />
      </ListItem>
    </List>
    <Divider />
    <List>
      <Tooltip title="Avaliação Social">
        <ListItem button component={Link} to="/avaliacao/social">
          <ListItemIcon>
            <AccessibilityIcon />
          </ListItemIcon>
          <ListItemText primary="Social" />
        </ListItem>
      </Tooltip>
      <Tooltip title="Avaliação de Terapia Ocupacional">
        <ListItem button component={Link} to="/avaliacao/terapia-ocupacional">
          <ListItemIcon>
            <AccessibleIcon />
          </ListItemIcon>
          <ListItemText primary="Terapia Ocupacional" />
        </ListItem>
      </Tooltip>
    </List>
    <Divider />
    <List>
      <Tooltip title="Termo de Responsabilidade">
        <ListItem button component={Link} to="/termos/responsabilidade">
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Responsabilidade" />
        </ListItem>
      </Tooltip>
      <Tooltip title="Termo de Retomada">
        <ListItem button component={Link} to="/termos/retomada">
          <ListItemIcon>
            <RotateRightIcon />
          </ListItemIcon>
          <ListItemText primary="Retomada" />
        </ListItem>
      </Tooltip>
    </List>
    <Divider />
    <List>
      <Tooltip title="Entrevista Fonoaudiológica">
        <ListItem button component={Link} to="/entrevistas/fonoaudiologo">
          <ListItemIcon>
            <HearingIcon />
          </ListItemIcon>
          <ListItemText primary="Fonoaudiologo" />
        </ListItem>
      </Tooltip>
    </List>
    <Divider />
    <List>
      <Tooltip title="Administrativo: Controle de Faltas">
        <ListItem
          button
          component={Link}
          to="/administrativo/controle-de-faltas"
        >
          <ListItemIcon>
            <RuleIcon />
          </ListItemIcon>
          <ListItemText primary="Controle de Faltas" />
        </ListItem>
      </Tooltip>
    </List>
    <Divider />
    <List>
      <Logout />
    </List>
  </div>
);
