import axios from "axios";

export const ApiUrl = process.env.REACT_APP_API_URL;

const request = axios.create({
  baseURL: ApiUrl + "/",
});

const headers = (jwt, isFileUpload) => ({
  headers: {
    Authorization: `Bearer ${jwt}`,
    ...(isFileUpload ? { "Content-Type": "multipart/form-data" } : {}),
  },
});

export const getSetorAtendimentos = () => request.get("setor-atendimentos");

export const login = (identifier, password) =>
  request.post("auth/local", {
    identifier,
    password,
  });

export const Pessoa = (jwt) => ({
  add: (data) => request.post("pessoas", data, headers(jwt)),
  update: (id, data) => request.put(`pessoas/${id}`, data, headers(jwt)),
  get: (params) => request.get("pessoas", { ...headers(jwt), params }),
  getById: (id) => request.get(`pessoas/${id}`, { ...headers(jwt) }),
});

export const DocumentoPessoa = (jwt) => {
  const parseToFormData = (d) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify({ ...d, documento: undefined }));
    formData.append(`files.documento`, d.documento[0], d.documento[0].name);
    return formData;
  };

  return {
    add: (data) =>
      request.post(
        "documento-pessoas",
        parseToFormData(data),
        headers(jwt, true)
      ),
    remove: (id) => request.delete(`documento-pessoas/${id}`, headers(jwt)),
    update: (id, data) =>
      request.put(`documento-pessoas/${id}`, data, headers(jwt)),
    get: (pessoaId) =>
      request.get(`documento-pessoas?pessoa=${pessoaId}`, {
        ...headers(jwt),
      }),
  };
};

export const Atendimento = (jwt) => ({
  add: (data) => request.post("atendimentos", data, headers(jwt)),
  update: (id, data) => request.put(`atendimentos/${id}`, data, headers(jwt)),
  get: (params) => request.get("atendimentos", { ...headers(jwt), params }),
  faltasInjustificadas: (anoBase) =>
    request.get(`atendimentos/find-ausencias/${anoBase}`, headers(jwt)),
  cancelList: (agendamentos, params) =>
    Promise.all(
      agendamentos.map((id) =>
        request.put(`atendimentos/${id}`, params, headers(jwt))
      )
    ),
});

export const AtendimentoAnexos = (jwt) => {
  const parseToFormData = (d) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify({ ...d, anexo: undefined }));
    formData.append(`files.anexo`, d.anexo, "mapaDentes.png");
    return formData;
  };

  return {
    add: (data) =>
      request.post("atendimento-anexos", parseToFormData(data), headers(jwt)),
    update: (id, data) =>
      request.put(
        `atendimento-anexos/${id}`,
        parseToFormData(data),
        headers(jwt)
      ),
    get: (id) =>
      request.get(`atendimento-anexos?atendimento=${id}`, headers(jwt)),
  };
};

export const Bairro = (jwt) => ({
  get: (params = {}) =>
    request.get("bairros", {
      ...headers(jwt),
      params: { _sort: "nome:asc", ...params },
    }),
});

export const SetorAtendimento = (jwt) => ({
  get: (params = {}) =>
    request.get("setor-atendimentos", {
      ...headers(jwt),
      params: { _sort: "nome:asc", ...params },
    }),
});

export const Profissional = (jwt) => ({
  get: () =>
    request.get("profissionals", {
      ...headers(jwt),
      params: { _sort: "nome:asc" },
    }),
});

export const Desligamento = (jwt) => ({
  add: (data) => request.post("desligamentos", data, headers(jwt)),
  get: (pessoaId) =>
    request.get(`desligamentos?pessoa=${pessoaId}`, {
      ...headers(jwt),
      params: { _sort: "data:desc" },
    }),
});
