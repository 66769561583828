import { styled } from "@mui/material/styles";

const drawerWidth = 240;
const PREFIX = "Layout";

export const classes = {
  container: `${PREFIX}-container`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  title: `${PREFIX}-title`,
  body: `${PREFIX}-body`,
};

export default styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: "flex",
  },
  [`& .${classes.toolbar}`]: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  [`& .${classes.toolbarIcon}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },
  [`& .${classes.menuButtonHidden}`]: {
    display: "none",
  },
  [`& .${classes.drawerPaper}`]: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
  },
  [`& .${classes.drawerPaperClose}`]: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.body}`]: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  "& .only-printer": {
    display: "none",
  },
  "@media print": {
    [`& .${classes.drawerPaper}`]: {
      display: "none",
    },
    [`& .${classes.toolbar}`]: {
      display: "none",
    },
    ".only-printer": {
      display: "block",
    },
    ".no-print": {
      display: "none",
    },
    [`& .${classes.body}`]: {
      height: "auto",
    },
  },
}));
