import { useEffect, useState } from "react";
import { usePessoa } from "../Pessoa/PessoaContext";
import { statusAgendamento } from "../Agendamento/AgendamentoContext";
import { Atendimento } from "../../api/api";
import { useAuth } from "../Auth/AuthContext";

export const useFaltasInjustificadas = () => {
  const { pessoa } = usePessoa();
  const { user } = useAuth();
  const [faltas, setFaltas] = useState([]);

  const load = async () => {
    const { data } = await Atendimento(user.jwt).get({
      pessoa: pessoa.id,
      status_in: [
        statusAgendamento.naoRealizado,
        statusAgendamento.pacienteNaoCompareceu,
      ],
      ausenciaJustificada_null: true,
      dataAgendamento_gte: new Date(new Date().getFullYear(), 0, 1),
      dataAgendamento_lte: new Date(new Date().getFullYear(), 11, 31),
      _sort: "dataAgendamento:asc",
      _limit: -1,
    });

    setFaltas(data);
  };

  useEffect(() => {
    if (!pessoa.id) return;
    load();
  }, [pessoa.id]);

  const removeFalta = (id) => {
    setFaltas(faltas.filter((f) => f.id !== id));
  };

  return { faltas, removeFalta };
};
