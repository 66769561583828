import { styled } from "@mui/material/styles";

const drawerWidth = 270;
const PREFIX = "PessoaAgenda";

export const classes = {
  positionRelative: `${PREFIX}-positionRelative`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  button: `${PREFIX}-button`,
  header: `${PREFIX}-header`,
  container: `${PREFIX}-container`,
  setoresAtendimento: `${PREFIX}-setoresAtendimento`,
  chip: `${PREFIX}-chip`,
  avatar: `${PREFIX}-avatar`,
};

export default styled("div")(
  ({ theme: { spacing, transitions, palette } }) => ({
    [`& .${classes.positionRelative}`]: {
      position: "relative",
    },
    [`& .${classes.drawerPaper}`]: {
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      height: "100vh",
    },
    [`& .${classes.drawerPaperClose}`]: {
      overflowX: "hidden",
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      width: 0,
      height: "100vh",
    },
    [`& .${classes.button}`]: {
      position: "absolute",
      right: spacing(1),
      backgroundColor: palette.background.paper,
      top: spacing(1),
      zIndex: 10,
    },
    [`& .${classes.header}`]: {
      textAlign: "left",
    },
    [`& .${classes.container}`]: {
      padding: spacing(1),
      textAlign: "center",
    },
    [`& .${classes.setoresAtendimento}`]: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      padding: `${spacing(1)}px ${spacing(0.5)}px`,
      "& > div": {
        padding: spacing(0.5),
        display: "flex",
      },
    },
    [`& .${classes.chip}`]: {
      width: 16,
      height: 16,
      borderRadius: 4,
      marginRight: spacing(0.5),
      display: "inline-block",
    },
    [`& .${classes.avatar}`]: {
      margin: "auto",
      marginBottom: spacing(1),
    },
  })
);
